<template>
  <div class="about">
	<h2>Оплата аренды студии ArtPro и съёмок</h2>

	<p>Если вы арендуете студию, то указывайте в описании платежа : Аренда студии, если вы заказываете съёмки, то : Заказ съёмок. Оплата производится через банк на сайте банковской системы с защищённым соединением. Вам будет отправлен чек на указанную электронную почту.</p>

	<!--style>.tinkoffPayRow{display:block;margin:1%;width:160px;}</style-->
	<!--script src="https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"></script-->
	<!--form name="TinkoffPayForm">
	  <input class="tinkoffPayRow" type="hidden" name="terminalkey" value="1624006515340"/>
	  <input class="tinkoffPayRow" type="hidden" name="frame" value="false"/>
	  <input class="tinkoffPayRow" type="hidden" name="language" value="ru"/>
	    <input class="tinkoffPayRow" type="hidden" name="reccurentPayment" value="false"/>
	    <input class="tinkoffPayRow" type="hidden" name="customerKey" value=""/>
	    <input class="tinkoffPayRow" type="text" placeholder="Сумма заказа" name="amount" required value="3000"/>
	    <input class="tinkoffPayRow" type="hidden" placeholder="Номер заказа" name="order" value=""/>
	    <input class="tinkoffPayRow" type="hidden" placeholder="Описание заказа ( аренда студии или заказ съёмок )" name="description"/>
	    <select class="tinkoffPayRow" id="comboboxOrderDescription"--> <!--Supplement an id here instead of using 'name'-->
			<!--option value="Заказ съёмок" selected>Заказ съёмок</option>
			<option value="Аренда студии">Аренда студии</option>
		</select>
	    <input class="tinkoffPayRow" type="text" placeholder="ФИО плательщика" name="name" required/>
	    <input class="tinkoffPayRow" type="text" placeholder="E-mail" name="email" required/>
	    <input class="tinkoffPayRow" type="text" placeholder="Контактный телефон" name="phone" required/>
	    <input class="tinkoffPayRow" type="hidden" name="receipt" value=''/>
	    <input class="tinkoffPayRow" type="submit" value="Оплатить"/>
	</form-->

	<form action="https://unitpay.ru/pay/433433-bc087">
		<div><input type="text" name="account" value="demo"></div>
		<div><input type="text" name="sum" value="3000"></div>
		<div><input type="text" name="desc" value="Описание платежа"></div>
		<div><input type="hidden" name="signature" value="3acadb8e926fe225715a5a7cb0a90d5cdcc5dcc9664155410f3cfe44e678758c"></div>
		<div><input class="btn" type="submit" value="Оплатить"></div>
	</form>

	<div class="VerticalSpacer_Middle"></div>
	<div class="VerticalSpacer_Middle"></div>
	<div class="VerticalSpacer_Middle"></div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
form div {
  margin-bottom: 5px;
}

</style>

<script>
// function handleSubmitEvent () {
// 	console.log ( "handleSubmitEvent () : Processing form submitting." );

// 	// .addEventListener("submit", myScript);

// 	document.getElementsByName("description")[0].value = document.getElementsById("comboBoxOrderDescription")[0].value;

// 	// "Email": "a@test.ru","Phone": "+79031234567",
// // 		document.getElementsByName("receipt")[0].value = `{
// // "EmailCompany": "b@test.ru","Taxation": "usn_income","Items": [ {"Name": "Наименование товара 1","Price": 10000,"Quantity": 1.00,
// // "Amount": 10000,"PaymentMethod": "full_prepayment","PaymentObject": "commodity","Tax": "vat10","Ean13": "0123456789" },
// // {"Name": "Наименование товара 2","Price": 20000,"Quantity": 2.00,"Amount": 40000,"PaymentMethod": "prepayment",
// // "PaymentObject": "service","Tax": "vat20"},{"Name": "Наименование товара 3","Price": 30000,"Quantity": 3.00,"Amount": 90000,
// // "Tax": "vat10"}]}`;
// }

async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export default {
    // data: () => ({
    //   ......data of your component
    // }),
    mounted() {
//     	const timeCurrent = new Date ();

//     	const styleTinkoff = document.createElement('style');
//     	styleTinkoff.type = 'text/css';
//     	// padding: 1px 2px;border-width: 2px;box-sizing: border-box;
// 		styleTinkoff.appendChild(document.createTextNode(
// `.tinkoffPayRow{display:block;margin:1%;width:100%;box-sizing: border-box;}
// .about form { width : 350px; }`
// 		));
// 		document.head.appendChild(styleTinkoff);

// 		const scriptTinkoff = document.createElement('script')
// 		scriptTinkoff.setAttribute('src', 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js')
// 		document.head.appendChild(scriptTinkoff);

// 		document.getElementsByName("TinkoffPayForm")[0].addEventListener("submit", function (inputEvent) {
// 			inputEvent.preventDefault ();

// 			const comboboxOrderDescription = document.getElementById("comboboxOrderDescription");
// 			const inputAmount = document.getElementsByName("amount")[0];

// 			document.getElementsByName("order")[0].value = timeCurrent.toISOString ();
// 			document.getElementsByName("description")[0].value = comboboxOrderDescription.value;

// 			// "Email": "a@test.ru","phone":"${document.getElementsByName("phone")[0].value}",
// 			document.getElementsByName("receipt")[0].value = `{
// "Email": "${document.getElementsByName("email")[0].value}",
// "EmailCompany": "RodionKarimov@yandex.ru","Taxation": "usn_income","Items": [ {"Name": "${comboboxOrderDescription.value}","Price": ${inputAmount.value * 100},"Quantity": 1.00,"Amount": ${inputAmount.value * 100},"PaymentMethod": "full_payment","PaymentObject": "service","Tax": "none"}]
// }`;

// 			pay(this);
// 			return false;
// 		});
    }
    // methods: {
    //   ......methods of your component
    // }
}
</script>
